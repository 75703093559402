<template>
  <div>
    <img src="@/assets/img/BANNER-CATALOG-DESKTOP.jpg" alt="" class="d-none d-lg-block" />
    <img src="@/assets/img/BANNER-CATALOG-MOBILE.jpg" alt="" class="d-lg-none" />
  </div>
</template>
<script>
export default {
  name: "CatalogBanner",
}
</script>